import React from 'react'
import logo from './logo.png'
import './header.scss'

export default () => (
  <>
    <header className="fixed">
      <div className="container">
        <div className="logo">
          <h1>
            <a href="/" aria-label="Home">
              <img alt="ewized" src={logo} style={{ height: '50px' }} />
            </a>
          </h1>
        </div>
      </div>
    </header>
    <div className="placeholder"></div>
  </>
)
