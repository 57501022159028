import React from 'react'
import Header from './header/header.js'
// import SocialCard from './social-card/social-card.js'
import Snow from './snow/snow.js'
import '@ewized/base-elements/base-elements.js'

export default () => (
  <div>
    <Snow />
    <div style={{ display: 'flex', height: '100vh', flexDirection: 'column', overflow: 'auto' }}>
    <Header/>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      {/* <e-card header="About me">
        <e-icon slot="right-icon">user</e-icon>
        <e-markdown>
I'm a graduate from **Cal Poly Pomona** with Bachelors of Computer Science Class of **2019**.
I go ahead and beyond to learn the latest technologies by taking on a personal projects that uses new technologies and embraces change into the project.
        </e-markdown>
      </e-card> */}
      {/* <SocialCard/> */}
      </div>
    </div>
  </div>
)
