import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))

// Install the service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then(registration => console.log(`ServiceWorker registration successful with scope: ${registration.scope}`))
    .catch(err => console.error('ServiceWorker registration failed: ', err))
}
