import React, { Component } from 'react'
import root from 'react-shadow'
import uuid from 'uuid/v4'

// Util function to sleep async functions
const sleep = async wait => {
  return new Promise(callback => window.setTimeout(callback, wait))
}

const IS_DECEMBER = new Date().getMonth() === 11
const GRAVITY = -9.87
const SNOW_MASS = 0.03
const SNOW_DENSITY = 0.00005

// styles for snow particles
const snowColor = '#fff'
const snowSize = '8px'
const snow = {
  position: 'absolute',
  display: 'block',
  backgroundColor: snowColor,
  borderRadius: snowSize,
  height: snowSize,
  width: snowSize,
}
// keep the particles in view
const snowBackground = {
  overflow: 'hidden',
  position: 'absolute',
  height: '100%',
  width: '100%',
  zIndex: -1,
}

/** The snow particle */
const Snow = ({ x, y, size }) => <div style={{ ...snow, bottom: y, left: x, transform: `scale(${size})` }}></div>

/** Render the snow particles */
class Snowing extends Component {
  state = {
    deltaTime: window.performance.now(),
    width: window.innerWidth,
    height: window.innerHeight,
    snowParticles: [],
  }

  windowResize() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  /** The game loop for the snow particles */
  async loop() {
    // run at 60hz
    await sleep(16.66)
    let { snowParticles, height, width } = this.state
    // randomly add another particle based on the snow density
    let snowDensity = width * height * SNOW_DENSITY
    if (snowParticles.length < snowDensity) {
      this.setState(lastState => ({ snowParticles: [ ...lastState.snowParticles, {
        id: uuid(),
        x: lastState.width * Math.random(),
        y: lastState.height,
        size: Math.random(),
      } ] }))
    }
    // Update the snow particle positions and remove particles when the go off screen
    let dt = window.performance.now()
    this.setState(lastState => ({
      deltaTime: dt,
      snowParticles: lastState.snowParticles
          .filter(({ x, y }) => y > 0 && x > 0 && x < this.state.width)
          .map(particle => ({
            ...particle,
            x: particle.x + Math.random() - 0.5,
            y: particle.y + particle.size * SNOW_MASS * GRAVITY * (dt - lastState.deltaTime)
          })),
    }))
    // request game loop
    this.lastStateFrameId = window.requestAnimationFrame(this.loop.bind(this))
  }

  componentDidMount() {
    this.lastStateFrameId = window.requestAnimationFrame(this.loop.bind(this))
    window.addEventListener('resize', this.windowResize.bind(this))
  }

  componentWillUnmount() {
    this.lastStateFrameId && window.cancelAnimationFrame(this.lastStateFrameId)
    window.removeEventListener(this.windowResize.bind(this))
  }

  render() {
    return (
      <root.div mode='closed' style={snowBackground}>
        {this.state.snowParticles.map(snow => <Snow {...snow} key={snow.id} />)}
      </root.div>
    )
  }
}

export default props => true || IS_DECEMBER ? <Snowing {...props} /> : null
